<template>
  <div class="view-error">
    <!-- <base-content-wrapper :compensateNavTop="true"> -->
      404 - Page not found
    <!-- </base-content-wrapper> -->

    <!-- <app-footer></app-footer> -->
  </div>
</template>

<script>
// TODO:

// Tips:
// Adding links to your most important pages (your best articles articles/content, latest posts, etc.)
// If you have an e-commerce business, you can display special offers, product recommendations, discounts, etc.
// Include a search box or a sitemap link to help users find the information they wanted.
// Use simple language (non-technical) to explain what went wrong.
// Add a contact form or provide an email address to allow users to get in touch with you for further help.

//import appFooter from "@/components/appFooter";

export default {
  name: "viewError",

  // components: { appFooter },

  head: {
    title: {
      inner: '404 - page not found'
    }
  }
};
</script>

<style lang="scss" scoped>
.view-error {
  padding: 10rem;
}
</style>
